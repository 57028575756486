
export default {
  layout: 'layout-full-page',
  mounted() {
    this.$fb.enable();
  },
  created() {
    this.$gtm.init('GTM-NK448G3');
  },
  methods: {
    signIn() {
      this.$auth.login();
    },
  },
};
